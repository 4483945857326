.btn-large {
  @apply px-4 py-1 bg-blue-600 text-white transition-all;
}
.btn-large.secondary {
  @apply bg-gray-300 text-gray-600;
}
.btn-large.secondary:hover {
  @apply bg-gray-600 text-white;
}
.btn-large:hover {
  @apply bg-blue-700;
}

.btn-line {
  @apply text-blue-600 hover:text-blue-500;
}
.btn-line.quiet {
  @apply text-neutral-600 hover:text-blue-500;
}

.btn-line.green {
  @apply text-green-600 hover:text-green-500;
}
.btn-line.green.quiet {
  @apply text-neutral-600 hover:text-green-500;
}
.btn-line.danger {
  @apply text-red-600 hover:text-red-500;
}
.btn-line.danger.quiet {
  @apply text-neutral-600 hover:text-red-500;
}

.btn-social {
  @apply bg-neutral-100 dark:bg-neutral-900 text-neutral-700 dark:text-gray-300 shadow-none border-line dark:border-line hover:bg-neutral-200 dark:hover:bg-neutral-800 transition-all py-2 flex flex-row items-center pl-16  pr-5 gap-x-3 rounded text-sm;
}
.btn-default {
  @apply inline-flex justify-center items-center px-2 md:px-3 py-2 rounded text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-blue-500 whitespace-nowrap;
}
.btn-default.small {
  @apply px-2 md:px-4 py-1 rounded text-xs md:text-sm cursor-pointer;
}

.btn-default.sell {
  @apply bg-rose-700 dark:shadow-rose-800 hover:bg-rose-700 focus:outline-none focus:ring-rose-500;
}

.btn-secondary {
  @apply inline-flex items-center px-4 py-2 border border-transparent rounded  text-sm text-primary opacity-70 hover:opacity-100;
}

.btn-default.disabled {
  @apply opacity-50 cursor-not-allowed bg-neutral-500;
}

.btn-default:disabled {
  @apply opacity-50 cursor-not-allowed bg-neutral-500;
}

.btn-default.secondary {
  @apply bg-neutral-100 dark:bg-neutral-900 text-neutral-700 dark:text-gray-300 shadow-none border-line dark:border-line hover:bg-neutral-200 dark:hover:bg-neutral-800 transition-all;
}

.btn-default.danger {
  @apply bg-red-600 dark:shadow-red-800 hover:bg-red-700 focus:outline-none focus:ring-red-500;
}

.btn-small {
  @apply bg-line py-1 rounded text-sm cursor-pointer;
}

.btn-default.large {
  @apply px-10 py-2.5 rounded mx-1  cursor-pointer;
}
.btn-small.active {
  @apply bg-blue-700 text-white;
}
.tab-button {
  @apply px-2 font-medium dark:font-normal   transition text-sm whitespace-nowrap border-b border-transparent;
}
.tab-button:not(.active):hover {
  @apply text-gray-900 border-gray-200 dark:border-gray-500 border-b dark:text-gray-300;
}
.tab-button.active {
  @apply border-blue-500 dark:border-blue-600 border-b-2 text-primary opacity-100;
}
.btn-large.active {
  @apply bg-blue-600 text-white;
}

.detail-list-item {
  @apply flex flex-row justify-between w-full py-1 text-sm rounded text-secondary;
}
.detail-list-item:nth-child(odd) {
  @apply bg-white;
}

.number {
}

.input-default-no-padding {
  @apply border border-line bg-background py-1 lg:py-2 rounded focus:border-blue-500 focus:ring-blue-500 focus:outline outline-none;
}

.input-default {
  @apply input-default-no-padding px-2 text-primary;
}

.label-default {
  @apply block text-xs text-primary opacity-80;
}

.input-range {
  @apply w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700;
}

.link-default {
  @apply text-blue-500 hover:text-blue-600 font-medium;
}
.link-secondary {
  @apply text-primary hover:opacity-100 opacity-80 font-medium;
}

.check-default {
  @apply h-4 w-4  rounded border-gray-300 dark:border-gray-700 bg-neutral-200 dark:bg-neutral-800 text-blue-600 focus:ring-blue-600 dark:checked:bg-blue-600;
}
