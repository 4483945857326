:root {
  --main-background: #fcfcfc;
  --primary: #000000;
  --primary-2: #eeeeee;
  --secondary: #000000;
  --secondary-2: #282828;
  --hover: rgba(255, 255, 255, 0.075);
  --hover-1: rgba(255, 255, 255, 0.15);
  --hover-2: rgba(255, 255, 255, 0.25);
  /*--selection: var(--purple);*/

  --landing-neutral: #fafafa;
  --text-base-color: #171717;
  --text-primary: #0a0a0a;
  --text-secondary: #838383;
  --line-color: #eaeaea;
  --gradient-main-b: linear-gradient(180deg, #fdfeff 0%, #ffffff 100%);

  --accents-0: #fdfdfd;
  --accents-1: #f6f6f6;
  --accents-2: #f4f4f4;
  --accents-3: rgb(249, 249, 249);
  --accents-4: #919191;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;

  --change-color-green: #00a230;
  --change-color-red: #db3201;

  --header-height: 80px;
  --vh100-offset: calc(100vh - var(--header-height));

  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.dark:root {
  --main-background: rgb(12, 12, 12);
  --primary: #dfdfd;
  --primary-2: #eeeeee;
  --secondary: #000000;
  --secondary-2: #282828;
  --hover: rgba(255, 255, 255, 0.075);
  --hover-1: rgba(255, 255, 255, 0.15);
  --hover-2: rgba(255, 255, 255, 0.25);
  /*--selection: var(--purple);*/

  --landing-neutral: #0c0c0c;
  --text-base-color: #ededed;
  --text-primary: #f0f0f0;
  --text-secondary: #838383;
  --line-color: rgb(40, 40, 40);
  --accent-line-color: rgb(70, 70, 70);
  --gradient-main-b: linear-gradient(180deg, #fdfeff 0%, #ffffff 100%);

  --accents-0: #212529;
  --accents-1: #0b0b0b;
  --accents-2: #101010;
  --accents-3: #0f0f0f;
  --accents-4: #adb5bd;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;

  --change-color-green: #00d455;
  --change-color-red: #ff000e;

  --header-height: 80px;
  --vh100-offset: calc(100vh - var(--header-height));

  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

/* input[type='date'] {
  background: transparent;
} */

#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #5900ff;
  --gradient-color-2: #0091ff;
  --gradient-color-3: #210075;
  --gradient-color-4: #000785;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  /*@apply outline-none ring-2 ring-pink ring-opacity-50;*/
}

html {
  scroll-behavior: smooth;
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  /*font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;*/
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background: var(--main-background) !important;
  font-family:
    'Inter',
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    'Helvetica Neue',
    'Helvetica',
    sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-base-color);
  image-rendering: -webkit-optimize-contrast;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  overflow-x: clip;
}
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.chart-md-wrap {
  @apply space-y-2;
}
.chart-md-wrap a {
  @apply text-blue-500 underline hover:text-blue-600 cursor-pointer;
}
.chart-md-wrap p {
  @apply whitespace-pre-wrap;
}
.chart-md-wrap ul {
  @apply list-disc pl-4 space-y-1;
}
.chart-md-wrap li {
}

.height-screen-helper {
  min-height: var(--vh100-offset);
}

.inset-center-x {
  left: 50%;
  transform: translate(-50%, 0%);
}
.hoverselect:hover {
  @apply bg-black;
  @apply text-green-50;
}

.main-hero {
  @apply w-screen h-screen bg-cover bg-center backdrop-filter;
  background-image: url('/background.jpg');
}

.pt-default {
  @apply pt-5 md:pt-8;
}
.pb-default {
  @apply pb-10 md:pb-20;
}
.mb-default {
  @apply mb-1 md:mb-3;
}
.content-default {
  @apply container mx-auto;
}

.icon-color {
  @apply dark:text-gray-500 text-gray-400 hover:text-gray-600 dark:hover:text-gray-400 duration-300;
}

.panel-divide {
  @apply *:py-3 md:*:py-5 md:gap-y-3 md:flex md:flex-col;
}
.divide-default {
  @apply *:py-2 md:*:py-2  *:border-line last:*:border-none *:border-b;
}

.panel-hover {
  @apply hover:brightness-110 transition-all duration-150 ease-in-out;
}
/* .panel-divide .panel {
  @apply first:pt-0 py-4 last:pb-0;
} */

.header-bg {
  @apply bg-slate-100 dark:bg-neutral-900;
}

.panel-header {
  @apply mb-1 md:mb-2 px-default text-xs  opacity-40;
}

.panel-bg {
  @apply md:bg-white md:dark:bg-black/90 print:bg-none backdrop-blur-xl;
}

.panel-border {
  @apply border-b border-line md:border-line md:border print:border-none;
}

.panel-rounded {
  @apply md:rounded-xl;
}

.panel-padding {
  @apply p-5 print:p-0;
}

.panel-no-bg {
  @apply panel-border panel-rounded;
}
.panel {
  @apply panel-bg panel-no-bg md:mx-0;
}

.px-default {
  @apply px-2 md:px-0;
}

.section-header {
  @apply text-white opacity-75 text-sm;
}

.kpi {
  @apply text-xl md:text-lg font-semibold text-primary;
}

.page-header {
  @apply text-primary tracking-tight font-bold text-2xl;
}

.scrollbar-thin {
  scrollbar-width: thin;
}
.scrollbar-none {
  scrollbar-width: none;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.nav-button {
  @apply flex justify-center  h-8 px-2 rounded items-center dark:hover:bg-neutral-600 hover:bg-neutral-100 transition-colors;
}
.nav-button-borderless {
  @apply flex justify-center  h-8 px-2 rounded items-center dark:hover:bg-neutral-600 hover:bg-neutral-100 transition-colors;
}

.link {
  @apply text-primary   hover:text-slate-300;
}

svg {
  strokewidth: 1;
}

.sticky-border {
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.table-sticky-border-r:after {
  @apply sticky-border;
  border-right: 1px solid var(--line-color);
}
.table-sticky-border-l:after {
  @apply sticky-border;
  border-left: 1px solid var(--line-color);
}

.backdrop {
  @apply bg-black bg-opacity-70;
}

.beta-warning {
  @apply border max-w-xl mb-5 p-3 rounded-xl leading-relaxed;
}
.beta-warning a {
  @apply font-medium underline;
}
.beta-warning.info a {
  @apply dark:text-blue-400 text-blue-600;
}
.beta-warning.warning a {
  @apply dark:text-amber-200 text-amber-700;
}
.beta-warning h2 {
  @apply text-xs font-bold mb-1;
}

.cms-page {
  @apply text-primary max-w-xl py-5 md:py-20 px-5;
}
.cms-page h1 {
  @apply text-2xl font-bold mb-2;
}
.cms-page h2 {
  @apply text-xl font-bold mb-2;
}
.cms-page h3 {
  @apply text-lg font-bold mb-2;
}
.cms-page p {
  @apply mb-8 opacity-80;
}

.landing-page .content-default {
  @apply px-5;
}

h1.lp {
  @apply text-6xl md:text-6xl tracking-tight font-medium  mb-2 leading-none;
}

h2.lp {
  @apply text-2xl md:text-4xl tracking-tight font-medium mb-2 leading-tight;
}
h3.lp {
  @apply text-xl md:text-3xl tracking-tight font-medium mb-2 leading-tight;
}
p.lp {
  @apply opacity-70 leading-relaxed  md:text-lg max-w-xl mx-auto;
}

.portfolio-header {
  @apply text-primary text-xl font-semibold mb-5;
}

.link-default {
  @apply text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300;
}

.h-qscreen {
  height: 100vh;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}

.card-heading {
  @apply font-semibold tracking-tight leading-snug text-primary text-lg;
}

.card-body {
  @apply opacity-90 text-[0.85rem] leading-snug text-pretty;
}

.quick-dd .p {
  @apply mb-5 text-[1.05rem] leading-normal opacity-90;
}

.quick-dd .caption {
  @apply block font-semibold text-xs text-right;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.blink {
  @apply animate-blink;
}

.link {
  @apply text-blue-500 hover:text-blue-400;
}

.json-markdown {
  @apply text-sm tracking-normal leading-relaxed;
}

.json-markdown h1 {
  @apply text-lg font-bold mb-1 leading-tight tracking-tight;
}
.json-markdown h2 {
  @apply font-bold mb-1 tracking-tight;
}
.json-markdown h3 {
  @apply font-bold mb-1;
}
.json-markdown h4 {
  @apply font-bold mb-1;
}
.json-markdown p {
  @apply mb-5;
}
.json-markdown p strong {
  @apply mb-5 text-primary;
}
.json-markdown ul {
  @apply mb-5 pl-5;
}
.json-markdown li {
  @apply mb-1 list-disc;
}
.json-markdown li strong {
  @apply text-primary;
}
.json-markdown a {
  @apply link-default  font-medium;
}

.range-slider {
  /* @apply bg-white dark:bg-black/90 border-line border; */
  background: transparent;
  height: 40px;
  /* background: #333; */
  overflow: hidden;
}

.range-slider .range-slider__thumb {
  width: 18px;
  height: 30px;
  border-radius: 6px;
  @apply border-line border bg-white dark:bg-neutral-500;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E");

  background-repeat: no-repeat;
  background-position: center;
}

.range-slider .range-slider__range {
  border-radius: 6px;
  background: transparent;
  @apply border border-line;
  /* border: 2px solid fff; */
  box-sizing: border-box;
  /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75); */
}

.faded {
  @apply text-primary opacity-80 hover:opacity-100 transition-opacity;
}

@keyframes fadeOut {
  0% {
    background-color: rgb(163 230 53);
  }
  100% {
    background-color: transparent;
  }
}

.fade-out {
  animation: fadeOut 2s forwards;
  animation-delay: 0.5s;
}

.checkbox-default {
  @apply h-4 w-4 hidden rounded border-gray-300 text-blue-600 focus:ring-blue-600;
}

.z-60 {
  z-index: 60;
}
/* 
:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
} */
.marquee-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 5%,
    hsl(0 0% 0% / 1) 95%,
    hsl(0 0% 0% / 0)
  );

  -webkit-mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 5%,
    hsl(0 0% 0% / 1) 95%,
    hsl(0 0% 0% / 0)
  );

  -moz-mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 5%,
    hsl(0 0% 0% / 1) 95%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--vertical {
  --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
  flex-direction: column;
}

.marquee--vertical .marquee__group {
  animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.tooltip-container .list-tooltip {
  @apply panel-border panel-bg;

  z-index: 400;
  opacity: 1;
}
